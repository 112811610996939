import React, { Component } from 'react'
import Warping from './helper/Warping'

class Donation extends Component {
  render() {
    return (
      <div className="container">
        <div className="donation-container">
          <Warping />
        </div>
      </div>
    )
  }

}

export default Donation
