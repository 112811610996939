import React, { Component } from 'react'
import PropTypes from 'prop-types'

class Modal extends Component {
  close = event => {
    event.preventDefault()

    if (this.props.onClose) this.props.onClose()
  }

  render () {
    if (!this.props.show) return null

    const backdropStyle = {
      position: 'fixed',
      width: '100vw',
      height: '100vh',
      top: 0,
      left: 0,
      zIndex: '9998',
      background: 'rgba(0, 0, 0, 0.3)'
    }

    const modalStyle = {
      position: 'fixed',
      top: '10%',
      bottom: '10%',
      left: '10%',
      right: '10%',
      zIndex: '9999',
      background: '#fff',
      maxWidth: '750px',
      overflow: 'scroll',
      borderRadius: '5px',
      
      transform: 'scale(1)',
      animation: 'blowUpModal .5s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards'
    }

    return (
      <div>
        <div style={modalStyle}>{this.props.children}</div>
        <div style={backdropStyle} onClick={this.close}/>
      </div>
    )
  }
}

Modal.propTypes = {
  onClose: PropTypes.func.isRequired,
  show: PropTypes.bool,
  children: PropTypes.node
}

export default Modal
