import React, { Component } from 'react'
import { connect } from 'react-redux'

import Messages from './Messages'

class Advent extends Component {
  state = {
    currentTab: 1,
    tabs: ["Messages"]
  }

  handleChangeTab = (event, i) => {
    event.preventDefault()

    this.setState({ currentTab: i })
  }

  renderTabs = () => {
    return this.state.tabs.map((tab, i) => {
      return (
        <li key={i}><span className={this.state.currentTab === i ? "active" : ""} onClick={e => this.handleChangeTab(e, i)}>{tab}</span></li>
      )
    })
  }

  renderTabContent = () => {
    switch (this.state.currentTab) {
      case 0:
        return <Messages />
      default:
        return <Messages />
    }
  }

  render() {
    return (
      <div className="advent-container">
        <ul className="tabs tabs-group">
          {this.renderTabs()}
        </ul>

        <div className="advent-content container">
          {this.renderTabContent()}
        </div>
      </div>
    )
  }
}

function mapStateToProps({ auth }) {
  return { auth }
}

export default connect(mapStateToProps)(Advent)
