import React, { Component } from 'react'
import { connect } from 'react-redux'
import Warping from '../helper/Warping'


class Dashboard extends Component {
  render() {
    // const Credits = this.props.auth && this.props.auth.credits
    return (
      <div className="dashboard-container">
        <Warping />
      </div>
    )
  }

}

function mapStateToProps({ auth }) {
  return { auth }
}

export default connect(mapStateToProps)(Dashboard)
