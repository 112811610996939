import { connect } from 'react-redux'
import { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`
  a {
    color: ${props => props.theme.GLOBAL_COLORS.a};
  }
  a:hover,
  a:focus {
    color: ${props => props.theme.GLOBAL_COLORS.aHover};
  }
  .active {
    color: ${props => props.theme.GLOBAL_COLORS.active};
  }

  .subtext {
    color: ${props => props.theme.GLOBAL_COLORS.subtext};
  }
  .subtext span:hover {
    color: ${props => props.theme.GLOBAL_COLORS.subtextHover.color};
  }

  .gray {
    color: ${props => props.theme.GLOBAL_COLORS.Gray};
    border-color: ${props => props.theme.GLOBAL_COLORS.Gray};
  }
  .gray:hover {
    color: ${props => props.theme.GLOBAL_COLORS.GrayHover};
    border-color: ${props => props.theme.GLOBAL_COLORS.GrayHover};
  }

  .green {
    color: ${props => props.theme.GLOBAL_COLORS.Green};
    border-color: ${props => props.theme.GLOBAL_COLORS.Green};
  }
  .green:hover {
    color: ${props => props.theme.GLOBAL_COLORS.GreenHover};
    border-color: ${props => props.theme.GLOBAL_COLORS.GreenHover};
  }

  .yellow {
    color: ${props => props.theme.GLOBAL_COLORS.Yellow};
    border-color: ${props => props.theme.GLOBAL_COLORS.Yellow};
  }
  .yellow:hover {
    color: ${props => props.theme.GLOBAL_COLORS.YellowHover};
    border-color: ${props => props.theme.GLOBAL_COLORS.YellowHover};
  }

  .red {
    color: ${props => props.theme.GLOBAL_COLORS.Red};
    border-color: ${props => props.theme.GLOBAL_COLORS.Red};
  }
  .red:hover {
    color: ${props => props.theme.GLOBAL_COLORS.RedHover};
    border-color: ${props => props.theme.GLOBAL_COLORS.RedHover};
  }

  .purple {
    color: ${props => props.theme.GLOBAL_COLORS.Purple};
    border-color: ${props => props.theme.GLOBAL_COLORS.Purple};
  }
  .purple:hover {
    color: ${props => props.theme.GLOBAL_COLORS.PurpleHover};
    border-color: ${props => props.theme.GLOBAL_COLORS.PurpleHover};
  }
`
const mapStateToProps = state => ({ 
	theme: state.theme
}) 

export default connect(mapStateToProps)(GlobalStyle)