module.exports = {
  capitalize: s => s.replace(/^\w/, chr => chr.toUpperCase()),

  parseDate: s => {
    var b = s.split(/\D/)
    return new Date(b[0], b[1]-1, b[2])
  },

  shorttenMessage: s => {
    let length = 70
    return s.substring(0, length) + '...'
  },

  parseTechToArray: string => string.replace(/\s/g, '').split(','),

  arraysEqual: (a, b) => {
    if (a === b) return true;
    if (a === null || b === null) return false;
    if (a.length !== b.length) return false;

    // If you don't care about the order of the elements inside
    // the array, you should sort both arrays here.
    // Please note that calling sort on an array will modify that array.
    // you might want to clone your array first.
    a.sort()
    b.sort()

    for (var i = 0; i < a.length; ++i) {
      if (a[i] !== b[i]) return false;
    }
    
    return true;
  }
}
