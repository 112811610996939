import styled from "styled-components"

const NavText = styled.span`
  position: relative;
  display: table-cell;
  vertical-align: middle;
  font-family: 'Josefin Sans', serif;
  padding-left: 10px;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  font-size: 12px;
`

export default NavText