import React, { Component } from 'react'
import styled from "styled-components"
import SpanLetters from '../templates/SpanLetters'
import SpanWords from '../templates/SpanWords'

import Modal from '../helper/Modal'

const ProjectContainer = styled.div`
	position: absolute;
	top: 15%;
	margin-top: 280px;
	width: 70%;

	@media screen and (max-width: 670px){
		margin-top: 380px;
	}

	@media screen and (max-width: 600px){
		left: 10%;
		margin-top: 280px;
	}	
`

const Section = styled.div`
	margin: 80px 0;

	& > h2 {
		color: white;
	}
`

const Column = styled.div`
	padding: 20px;
	background-color: #e2e7ef;

	width: 100%;
	max-width: 100%;

	& > h3 {
		color: #0050a5;
	}

	& > img {
		width: 100%;
	}

	@media screen and (min-width: 900px) {
		flex-basis: 0;
		flex-grow: 1;
	}
`

const WebLink = styled.a`
	display: inline-block;
	font-size: 18px;
	padding: .5rem .5rem .2rem .5rem;
	margin-bottom: 10px;
	background-color: #3670d0;
	color: white;

	& > i {
		font-size: 10px;
		vertical-align: top;
		margin-left: 5px;
	}

	&:hover {
		color: white;
		background-color: #2759ad;
	}

	&:visited {
		color: white;
	}
`

class SamsFriedIceCream extends Component {
	state = {
		activeModal: false,
		modalImage: "https://asolace.s3.amazonaws.com/Samsfriedicecream_BEFORE_Home_02-11-2020.png"
	}

	openModal = (e, s) => {
    this.setState({ 
			activeModal: true,
			modalImage: e.target.dataset.img
		})
  }
  closeModal = () => {
    this.setState({ activeModal: false })
  }

	render() {
		const { activeModal, modalImage } = this.state

		return (
			<div className="container">
				<div className="main-title">
					<SpanLetters str="Sam's Fried Ice Cream" /> 

					<div className="subtext">
						<WebLink href="http://www.samsfriedicecream.com/" target="_blank" rel="noopener noreferrer">
							Visit Website 
							<i className="fas fa-external-link-alt"></i>
						</WebLink>
						<br/>
						<SpanWords str="
							Sam’s Fried Ice Cream located in downtown Manhattan is one of the best ice cream shops in the city. 
							They specialized in mixing the best of 2 worlds; Hot, salty crunchy bites into an ice-cold, sweet, delectable delight.
							/n /n 
							I wanted their website to reflect the excellent dessert they serve. 
							The website was transformed into a modern warm and inviting feel. 
							This increased unique visitors by 23% and average visit duration by 30%.
						"/>
					</div>
				</div>
	
				<ProjectContainer>
					<Section>
						<h2>Home Page</h2>
						<div className="box">
							<Column>
								<h3>Before</h3>
								<img 
									src="https://asolace.s3.amazonaws.com/Samsfriedicecream_BEFORE_Home_02-11-2020.png" 
									data-img="https://asolace.s3.amazonaws.com/Samsfriedicecream_BEFORE_Home_02-11-2020.png"
									alt="home-before" 
									onClick={e => this.openModal(e)}
								/>
							</Column>
							<Column>
								<h3>After</h3>
								<img 
									src="https://asolace.s3.amazonaws.com/Samsfriedicecream_Home_02-11-2020.png" 
									data-img="https://asolace.s3.amazonaws.com/Samsfriedicecream_Home_02-11-2020.png" 
									alt="home-after"
									onClick={e => this.openModal(e)}
								/>
							</Column>
						</div>
					</Section>
				
					<Section>
						<h2>About Page</h2>
						<div className="box">
							<Column>
								<h3>Before</h3>
								<img 
									src="https://asolace.s3.amazonaws.com/Samsfriedicecream_BEFORE_About_02-11-2020.png" 
									data-img="https://asolace.s3.amazonaws.com/Samsfriedicecream_BEFORE_About_02-11-2020.png"
									alt="about-before" 
									onClick={e => this.openModal(e)}
								/>
							</Column>
							<Column>
								<h3>After</h3>
								<img 
									src="https://asolace.s3.amazonaws.com/Samsfriedicecream_About_02-11-2020.png" 
									data-img="https://asolace.s3.amazonaws.com/Samsfriedicecream_About_02-11-2020.png" 
									alt="about-after"
									onClick={e => this.openModal(e)}
								/>
							</Column>
						</div>
					</Section>

					<Section>
						<h2>Events Page</h2>
						<div className="box">
							<Column>
								<h3>Before</h3>
								<img 
									src="https://asolace.s3.amazonaws.com/Samsfriedicecream_BEFORE_Events_02-11-2020.png" 
									data-img="https://asolace.s3.amazonaws.com/Samsfriedicecream_BEFORE_Events_02-11-2020.png"
									alt="events-before" 
									onClick={e => this.openModal(e)}
								/>
							</Column>
							<Column>
								<h3>After</h3>
								<img 
									src="https://asolace.s3.amazonaws.com/Samsfriedicecream_Events_02-11-2020.png" 
									data-img="https://asolace.s3.amazonaws.com/Samsfriedicecream_Events_02-11-2020.png" 
									alt="events-after"
									onClick={e => this.openModal(e)}
								/>
							</Column>
						</div>
					</Section>

					<Section>
						<h2>Gallery Page</h2>
						<div className="box">
							<Column>
								<h3>Before</h3>
								<img 
									src="https://asolace.s3.amazonaws.com/Samsfriedicecream_BEFORE_Gallery_02-11-2020.png" 
									data-img="https://asolace.s3.amazonaws.com/Samsfriedicecream_BEFORE_Gallery_02-11-2020.png"
									alt="gallery-before" 
									onClick={e => this.openModal(e)}
								/>
							</Column>
							<Column>
								<h3>After</h3>
								<img 
									src="https://asolace.s3.amazonaws.com/Samsfriedicecream_Gallery_02-11-2020.png" 
									data-img="https://asolace.s3.amazonaws.com/Samsfriedicecream_Gallery_02-11-2020.png" 
									alt="gallery-after"
									onClick={e => this.openModal(e)}
								/>
							</Column>
						</div>
					</Section>

					<Section>
						<h2>Contact Page</h2>
						<div className="box">
							<Column>
								<h3>Before</h3>
								<img 
									src="https://asolace.s3.amazonaws.com/Samsfriedicecream_BEFORE_Contact_02-11-2020.png" 
									data-img="https://asolace.s3.amazonaws.com/Samsfriedicecream_BEFORE_Contact_02-11-2020.png"
									alt="contact-before" 
									onClick={e => this.openModal(e)}
								/>
							</Column>
							<Column>
								<h3>After</h3>
								<img 
									src="https://asolace.s3.amazonaws.com/Samsfriedicecream_Contact_02-11-2020.png" 
									data-img="https://asolace.s3.amazonaws.com/Samsfriedicecream_Contact_02-11-2020.png" 
									alt="contact-after"
									onClick={e => this.openModal(e)}
								/>
							</Column>
						</div>
					</Section>
				</ProjectContainer>
	
				<Modal show={activeModal} onClose={this.closeModal}>
					<img style={{ width: '100%' }} src={modalImage} alt="zoomed-img"/>
				</Modal>
				
			</div>
		)
	}
}

export default SamsFriedIceCream