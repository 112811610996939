import React from 'react'
import { Link } from 'react-router-dom'

const RedEnvelope = () => {
  return (
    <Link to="/contact">
      <div className="envelope-image">
        <div className="animated-mail">
          <div className="back-fold"></div>
          <div className="letter">
            <div className="letter-border"></div>
            <div className="letter-title"></div>
            <div className="letter-context"></div>
            <div className="letter-stamp">
              <div className="letter-stamp-inner">asolace</div>
            </div>
          </div>
          <div className="top-fold"></div><p className="envelope-header">Contact Me</p>
          <div className="body"></div>
          <div className="left-fold"></div>
        </div>
        <div className="shadow"></div>
      </div>
    </Link>
  )
}

export default RedEnvelope
