import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from "redux"
import { displayGlobalAlert } from '../../../actions'
import { Helmet } from 'react-helmet'
import axios from 'axios'

import ProjectContainer from './ProjectsContainer'
import ProjectAdminControls from './ProjectAdminControls'
import ProjectModal from './ProjectModal'
import TechFilter from './TechFilter'
import AddProject from './AddProject'

import SpanLetters from '../../templates/SpanLetters'

import { SocialMediaBtns } from '../../features/SocialMediaBtns'
import LoadingBar from '../../helper/LoadingBar'

class Projects extends Component {
  state = {
    projects: [],
    fetching: true
  }

  async componentDidMount() {
    let response = await axios.get('/api/get_projects')
    this.setState({ projects: response.data.projects, fetching: false })
  }

 showAllProjects = async e => {
    try {
      let response = await axios.get('/api/get_all_projects')
      this.setState({ projects: response.data.projects, fetching: false })
    } catch (err) {
      this.props.displayGlobalAlert("ALERT_DANGER", "Failed to get all projects.")
      console.log(err)
    }
  }

  renderProjects = () => {
    return this.state.projects.map((project, i) => {
      return (
        <ProjectModal key={i} project={project} index={i}/>
      )
    })
  }

  renderFilteredProjects = async filterList => {
    try {
      let res = await axios.get('/api/get_filtered_projects', {
        params: {
          filterList
        }
      })

      this.setState({ projects: res.data.projects })
    } catch (err) {
      this.props.displayGlobalAlert("ALERT_DANGER", "Failed to get filtered projects.")
      console.log(err)
    }
  }

  render() {
    const { auth } = this.props

    return (
      <div className="container">
        <Helmet>
          <meta charSet="utf-8" />
          <title>Asolace - Projects</title>
          <meta name="description" content="Asolace's projects include website redesigns, inovative and unique applicaiton solutions." />
          <meta name="keywords" content="software, engineer, web developer, personal website, react, projects" />
        </Helmet>

        <div className='main-title'>

          {auth && auth.tea && 
            <ProjectAdminControls>
              <button className="yellow" onClick={this.showAllProjects}>Show All Projects</button>
            </ProjectAdminControls>
          }

          <SpanLetters str="Projects" />

          <SocialMediaBtns btns={["github"]}/>
        </div>

        <div className="subcontent">
          <h3 style={{ margin: '20px 0 0 2px', color: 'white' }}>Filters:</h3>
          <TechFilter className="box" renderFilteredProjects={this.renderFilteredProjects}/>
          
          <ProjectContainer className="box">
            {this.state.projects && this.renderProjects()}

            {auth && auth.tea && <AddProject/>}
          </ProjectContainer>
        </div>

        {this.state.fetching && <LoadingBar />}
      </div>
    )
  }
}

function mapStateToProps({ auth }) {
  return { auth }
}

const mapDispatchToProps = dispatch => ({
  displayGlobalAlert: bindActionCreators(displayGlobalAlert, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(Projects)
