import React from 'react'
import styled from 'styled-components'

const ErrorPage = styled.div`
	position: absolute;
	display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 100%;
	overflow: hidden;

	@media screen and (max-width: 600px) {
		left: 20%;
	}
`

const Header = styled.h1`
	font-size: 30vh;
	font-weight: bold;
	position: relative;
	margin: -8vh 0 0;
	padding: 0;

	&:after {
		content: attr(data-h1);
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		color: transparent;
		/* webkit only for graceful degradation to IE */
		background: -webkit-repeating-linear-gradient(-45deg, #71b7e6, #69a6ce, #b98acc, #ee8176, #b98acc, #69a6ce, #9b59b6);
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
		background-size: 400%;
		text-shadow: 1px 1px 2px rgba(255, 255, 255, 0.25);
		animation: animateTextBackground 10s ease-in-out infinite;
	}

	@media screen and (max-width: 600px) {
		font-size: 32vw;
	}
`

const Text = styled.p`
	color: #d6d6d6;
  font-size: 8vh;
  font-weight: bold;
  line-height: 10vh;
  max-width: 600px;
	position: relative;
	
	&:after {
		content: attr(data-p);
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		color: transparent;
		text-shadow: 1px 1px 2px rgba(255, 255, 255, 0.5);
		-webkit-background-clip: text;
		-moz-background-clip: text;
		background-clip: text;
	}

	@media screen and (max-width: 600px) {
		font-size: 8vw;
    line-height: 10vw;
    max-width: 70vw;
	}
`

const NotFound = () => {
	return (
		<div className="container">
			<ErrorPage>
				<div>
					<Header data-h1="404">404</Header>
					<Text data-p="NOT FOUND">NOT FOUND</Text>
				</div>
			</ErrorPage>
		</div>
	)
}

export default NotFound