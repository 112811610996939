import React from 'react'

const SpanText = props => {
  return props.str.split('').map((letter, i, word) => {
    if (letter === "/" && word[i+1] === "n") {
      letter = <br/>
      word[i+1] = ""
    }
    
    return <span key={i}>{letter}</span>
  })
}

export default SpanText
