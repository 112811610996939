import React from 'react'
import styled from "styled-components"

const ProjectGithubStyle = styled.a`
  margin-left: 10px;
  color: #5d5d5d;
  font-size: 1.3rem;

  &:hover, &:focus {
    color: #444444;
  }
`

export const ProjectImageEditOverlay = styled.div`
  position: relative;
  max-width: 750px;
  height: auto;
  /* max-height: 750px; */
  overflow: scroll;

  & > p {
    position: absolute;
    bottom: 0;
    margin: 0 auto;
    width: 100%;
    padding: 60px 0;
    opacity: 0;
    background-color: black;
    color: white;
    letter-spacing: 2px;
    text-align: center;
    cursor: pointer;
  }

  & > img {
    width: 100%;

    &:hover {
      z-index: 0;
    }
  }

  &:hover {
    z-index: 1;

    & > p {
      opacity: 0.8;
    }
  }
`

export const ProjectInputContainer = styled.div`
  margin: 20px 50px;
  text-align: left;

  & > label {
    color: #565656;
    font-weight: bold;
  }
`

export const ProjectModalDetails = styled.div`
  margin: 50px;
  text-align: left;
  letter-spacing: 1px;

  & > hr {
    opacity: 0.3;
  }
`

export const ProjectModalTitle = styled.h5`
  margin: 10px 0;
  font-size: 25px;
  color: #565656;
  font-weight: bold;
  letter-spacing: 1px;
`


export const ProjectGithub = ({ link }) => {
  return (
    <ProjectGithubStyle href={link} target="_blank" rel="noopener noreferrer">
      <i className="fa fa-github"/>
    </ProjectGithubStyle>
  )
}

export const ProjectModalText = styled.p`
  font-size: 15px;
  color: #333;
  letter-spacing: 1px;
  line-height: 1.5;
  margin-bottom: 30px;
  text-align: left;
`

export const ProjectModalTags = styled.span`
  background-color: #d0e5ff;
  padding: 6px 15px 3px 15px;
  margin: 8px 10px 8px 0;
  border-radius: 20px;
  font-size: 15px;
`

export const ProjectModalViewProject = styled.a`
  padding: .5rem;
  background-color: #3670d0;
  color: white;

  &:hover {
    color: white;
    background-color: #2759ad;
  }

  &:visited {
    color: white;
  }
`

export const ProjectAdminModalEdits = styled.div`
  margin-top: 50px;
`

export const ProjectHideButton = styled.button`
  border: none;
  font-size: 18px;
  margin-bottom: 20px;
  cursor: pointer;

  & > i {
    color: #333;
  }
`