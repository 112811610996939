import React, { Component } from 'react'
import axios from 'axios'

import utilities from '../../utilities'
import Modal from '../../helper/Modal'

class Messages extends Component {
  state = {
    activeModal: null,
    messages: []
  }

  async componentDidMount() {
    let res = await axios.get('/api/asolace_messages')
    this.setState({ messages: res.data.messages })
  }

  openModal = (e, i, messageId) => {
    let updateReadMessages = this.state.messages
    updateReadMessages[i].read = true

    this.setState({
      activeModal: i,
      messages: updateReadMessages
    })

    axios.post('/api/set_message_read', {
      messageId: messageId
    })
  }

  closeModal = () => {
    this.setState({ activeModal: null })
  }

  renderMessages() {
    const { messages } = this.state
    return messages.map((message, i) => {
      let date = String(utilities.parseDate(message.dateRecieved)).split('00:00:00')[0]
      let shortMessage = utilities.shorttenMessage(message.message)
      return (
        <tr key={i} onClick={e => this.openModal(e, i, message._id)}>
          <td>{i}</td>
          <td>{date}</td>
          <td>{message.name}</td>
          <td>{message.email}</td>
          <td>{shortMessage}</td>
          <td>{message.read ? "Read" : "Unread"}</td>
        </tr>
      )
    })
  }

  render() {
    const { activeModal, messages } = this.state
    
    return (
      <div className="messages-container">
        <table className="message-table" cellSpacing="0">
          <thead>
            <tr>
              <th width="10">#</th>
              <th width="80">Date</th>
              <th width="150">Name</th>
              <th width="150">Email</th>
              <th>Message</th>
              <th>Read</th>
            </tr>
          </thead>
          <tbody>
            {this.renderMessages()}
          </tbody>
        </table>

        {messages.length > 0 && activeModal !== null &&
          <Modal show={this.state.activeModal !== null} onClose={this.closeModal}>
            <div>
              <h2>{messages[activeModal].name}</h2>
              {messages[activeModal].message}<br/><br/>
              {messages[activeModal].email}
            </div>
          </Modal>
        }
      </div>
    )
  }
}

export default Messages
