import styled from "styled-components"

const MainNav = styled.ul`
  height: 100%;
  outline: 0;
  margin: 0;
  padding: 0;

  @media screen and (max-width: 600px) {
    & > a {
      display: block;
      padding-left: 4px;
      position: relative;
      margin: 0px !important;
      width: 150px !important;
      padding: 5px;
      right: 10px;
      background-color: ${props => props.theme.mediaBackgroundColor};
    }

    &:hover {
      width: 100%;
    }
  }
`

export default MainNav