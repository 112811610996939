import React from 'react'
import PropTypes from 'prop-types';

const inputStyle = {
  display: 'block',
  width: '50%',
  // minWidth: '200px',
  height: '20px',
  fontSize: '1rem',
  padding: '.5rem',
  border: '1px solid gray',
  background: 'rgba(255,255,255,0.1)',
  color: 'gold',
  borderRadius: '2px',
}

const Input = ({ placeholder, inputName, inputChange }) => {
  return (
    <div className="input-wrapper">
      <input 
        type="text" 
        style={inputStyle} 
        placeholder={placeholder} 
        name={inputName} 
        onChange={inputChange}
    />
    </div>
  )
}

Input.propTypes = {
  placeholder: PropTypes.string,
  inputChange: PropTypes.func.isRequired,
  inputName: PropTypes.string,
  imputRequired: PropTypes.bool
}

export default Input