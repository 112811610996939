import React from 'react'
import styled from 'styled-components';
import PropTypes from 'prop-types';

const AlertStyle = styled.div`
  text-align: center;
  width: 70%;
  margin: auto;

  position: relative;
  top: 10px;
  padding: .75rem 1.25rem;
  border: 1px solid transparent;
`

const Alert = ({ alertType, alertMessage  }) => {
  return (
		<AlertStyle className={alertType}>
			{alertMessage}
		</AlertStyle>
	)
}

Alert.propTypes = {
  alertMessage: PropTypes.string,
  alertType: PropTypes.string,
}

export default Alert