import styled from "styled-components"
import NavText from "./NavText"

export const BrandMain = styled.a`
  padding-left: 4px;

  position: relative;
  display: table;
  border-collapse: collapse;
  border-spacing: 0;
  font-family: 'Assistant', serif;
  font-size: 13px;
  text-decoration: none;
  transform: translateZ(0) scale(1,1);
  transition: all .1s linear;
`

export const BrandSpan = styled(NavText)`
  font-size: 13px;
  font-weight: bold;

  @media screen and (max-width: 600px) {
    display: none;
  }
`