import React, { Component } from 'react'
import { connect } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { ThemeProvider } from "styled-components"

import { BrandMain, BrandSpan } from "./Brand"
import MainMenu from "./MainMenu"
import MainNav from "./MainNav"
import NavText from "./NavText"
import { JackInfo, UserInfo, Login, DisabledLogin, NavMedia, FA } from "./Section"

import AsolaceLogo from '../../icons/AsolaceLogo.svg'

class Sidebar extends Component {
  state = {
    renderLogin: true,
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { SIDEBAR } = nextProps.theme
    
		if (SIDEBAR.border !== prevState.navBorder) {
			return ({ navBorder: SIDEBAR.border })
		}

		return null
	}

  renderRoundedCredits() {
    let credits = this.props.auth.credits
    const DecPlaces = Math.pow(10,1)
    const Abbrev = [ "k", "m", "b", "t" ]

    for (let i = Abbrev.length - 1; i >= 0; i--) {
      let size = Math.pow(10,(i+1)*3)
      if(size <= credits) {
         credits = Math.floor(credits*DecPlaces/size)/DecPlaces
         if(credits === 1000 && i < Abbrev.length - 1) {
           credits = 1
           i++
         }
         credits += Abbrev[i]
         break
      }
    }
    return credits
  }

  renderDisableBtn() {
    return (
      <DisabledLogin>
        <FA className="fa fa-power-off fa-md"></FA>
        <NavText>Disabled</NavText>
      </DisabledLogin>
    )
  }

  renderLoginBtn() {
    switch (this.props.auth) {
      case null:
        return (
          <a href="/">
            <FA className="fa fa-power-off rainbow-animation fa-spin fa-md"></FA>
            <NavText className="rainbow-animation">Loading</NavText>
          </a>
        )
      case false:
        return (
          <a href="/auth/google">
            <FA className="fa fa-power-off fa-md"></FA>
            <NavText>Login</NavText>
          </a>
        )
      default:
        return (
          <a href="/api/logout">
            <FA className="fa fa-power-off fa-md"></FA>
            <NavText>Logout</NavText>
          </a>
        )
    }
  }

  renderDashboard() {
    if (this.props.auth.tea) {
      return (
        <NavLink to="/adm-dashboard">
          <FA className="fa fa-crown fa-md"></FA>
          <NavText>User</NavText>
        </NavLink>
      )
    } else {
      return (
        <span>
          <NavLink to="/donation">
            <FA className="fa fa-beer fa-md"></FA>
            <NavText>Credits: {this.renderRoundedCredits()}</NavText>
          </NavLink>
          <NavLink to="/dashboard">
            <FA className="fa fa-user fa-md"></FA>
            <NavText>User</NavText>
          </NavLink>
        </span>
      )
    }
  }

  renderUser() {
    if (this.props.auth) {
      return (
        <MainNav>
          <NavMedia>
            <FA className="fa fa-user fa-md"></FA>
            <NavText>User</NavText>
          </NavMedia>
          {this.renderDashboard()}
        </MainNav>
      )
    } else {
      return null
    }
  }

  render() {
    return (
      <ThemeProvider theme={this.props.theme.SIDEBAR}>
        <MainMenu id="main-menu">
          <BrandMain href="/" id="brand">
            <img src={AsolaceLogo} width="60px" className="rainbow-animation" alt="Asolace Logo"/>
            <BrandSpan className="rainbow-animation">Asolace</BrandSpan>
          </BrandMain>

          <MainNav>
            <JackInfo>
              <MainNav>
                <NavMedia>
                  <FA className="fa fa-user-astronaut fa-md"></FA>
                  <NavText>Jack</NavText>
                </NavMedia>
                <NavLink exact to="/">
                  <FA className="fa fa-home fa-md"></FA>
                  <NavText>Home</NavText>
                </NavLink>
                <NavLink to="/about">
                  <FA className="fa fa-user-astronaut fa-md"></FA>
                  <NavText>About</NavText>
                </NavLink>
                <NavLink to="/projects">
                  <FA className="fa fa-code fa-md"></FA>
                  <NavText>Projects</NavText>
                </NavLink>
                <NavLink to="/contact" href="/">
                  <FA className="fa fa-phone fa-md"></FA>
                  <NavText>Contact</NavText>
                </NavLink>

                {/*
                  <NavLink to="/donation" href="/">
                    <FA className="fa fa-gift fa-md"></FA>
                    <NavText>Donate</NavText>
                  </NavLink>
                */}
              </MainNav>
            </JackInfo>

            <UserInfo>
              {this.renderUser()}
            </UserInfo>

            <Login>
              {!this.state.renderLogin && this.renderDisableBtn()}
              {this.state.renderLogin && this.renderLoginBtn()}
            </Login>

          </MainNav>
        </MainMenu>
      </ThemeProvider>
    )
  }
}

function mapStateToProps({ auth, theme }) {
  return { auth, theme }
}

export default connect(mapStateToProps, null, null, { pure: false })(Sidebar)
