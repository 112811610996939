import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from "redux";
import { displayGlobalAlert } from '../../../../actions'
import axios from 'axios'

import utilities from '../../../utilities'
import { ProjectCard, ProjectCardHeader } from '../ProjectCard'
import Modal from '../../../helper/Modal'
import Dropzone from '../../../helper/Dropzone'
import InputWithBtn from '../../../helper/StandardComponents/InputWithBtn'
import Input from '../../../helper/StandardComponents/Input'
import Alert from '../../../helper/StandardComponents/Alert'
import { ProjectInputContainer } from '../ProjectModal/ProjectModalStyle'

import AddIcon from './AddIcon'

const style = {
	border: '5px dashed #e2e2e2',
	height: '245px',
	width: '290px',
	borderRadius: '5px',
	color: '#e2e2e2',
	fontSize: '10rem'
}

class AddProjects extends Component {
	state = {
		activeModal: null,
		formError: false,

		name: "",
		description: "",
		url: "",
		github: "",
		technologies: "",
		imgUrl: "Image Url",
	}

	openModal = e => {
    this.setState({ activeModal: "add-project" })
  }
  closeModal = () => {
    this.setState({ activeModal: null })
	}

	handleInputChange = e => {
		this.setState({ 
			...this.state, 
			[e.target.name]: e.target.value 
		})
	}

	handleInputUrlChange = e => {
		this.setState({ 
			imgUrl: e.target.value
		})
  }

	handleEditImageUrlSubmit = async e => {
		const { name, description, url, github, technologies, imgUrl } = this.state

		if (this.validateForm()) {
			try {
				let res = await axios.post('/api/add_project', {
					name,
					description,
					url,
					github,
					imgUrl,
					technologies: utilities.parseTechToArray(technologies)
				})

				if (res.data.success) {
					this.setState({ 
						activeModal: null,
						name: "",
						description: "",
						url: "",
						github: "",
						technologies: "",
						imgUrl: "Image Url"
					})

					this.props.displayGlobalAlert("ALERT_SUCCESS", `Successfully added new project.`)
				}
			} catch (err) {
				this.props.displayGlobalAlert("ALERT_DANGER", `Failed to add new project.`)
				console.log("Fail to update...", err)
			}
		}
	}
	
	handleImageDropSubmit = async (e, files) => {
		const { name, description, url, github, technologies } = this.state
		const File = files[0]

		if (File !== undefined && this.validateForm()) {
			try {
				let res = await axios.post('/api/upload_to_aws_s3', {
					filename: File.name,
					filetype: File.type
				})

				let options = {
					headers: {
						'Content-Type': File.type,
						'x-amz-acl': 'public-read'
					}
				}

				if (res.data.success) {
					let awsRes = await axios.put(res.data.uploadUrl, File, options)

					if (awsRes.status === 200) {
						let awsImgUrl = awsRes.config.url.split("?")[0]

						try {
							let resAddProject = await axios.post('/api/add_project', {
								name,
								description,
								url,
								github,
								imgUrl: awsImgUrl,
								technologies: utilities.parseTechToArray(technologies)
							})

							if (resAddProject.data.success) {
								this.setState({ 
									activeModal: null,
									name: "",
									description: "",
									url: "",
									github: "",
									technologies: "",
									imgUrl: "Image Url"
								})
			
								this.props.displayGlobalAlert("ALERT_SUCCESS", `Successfully added new project.`)
							}
						} catch (err) {
							this.props.displayGlobalAlert("ALERT_DANGER", `Failed to add new project.`)
							console.log("Fail to update...", err)
						}
					} else {
						this.props.displayGlobalAlert("ALERT_DANGER", `Failed to upload to AWS bucket with status ${awsRes.status}.`)
					}
				}
			} catch (err) {
				this.props.displayGlobalAlert("ALERT_DANGER", `Failed to add new project.`)
				console.log("Fail to update...", err)
			}
		} else {
			this.props.displayGlobalAlert("ALERT_DANGER", `No Image in dropzone or form not filled out.`)
		}
	}

	validateForm = () => {
		const { name, description, url, technologies } = this.state

		if (
			name === "" ||
			description === "" ||
			url === "" ||
			technologies === ""
		) {
			this.setState({ formError: true })
			return false
		} 

		this.setState({ formError: false })
		return true
	}

	render() {
		const { auth } = this.props
		const { activeModal, imgUrl, formError } = this.state

		return (
			<>
				<ProjectCard style={style}  onClick={e => this.openModal(e)}>
					<AddIcon>+</AddIcon>
				</ProjectCard>

				<Modal show={activeModal === "add-project"} onClose={this.closeModal}>
					<ProjectCardHeader>
						<a href="true">Add Project</a>
					</ProjectCardHeader>

					{formError && <Alert alertMessage="Some fields are missing to add project." alertType="alert-danger" /> }
					
					{auth && auth.tea && 
						<div style={{ textAlign: 'center' }}>
							<ProjectInputContainer>
								<label>Project Name</label>
								<Input 
									placeholder="Project Name"
									inputName="name"
									inputChange={this.handleInputChange}
								/>
							</ProjectInputContainer>

							<ProjectInputContainer>
								<label>Description</label>
								<Input 
									placeholder="Description" 
									inputName="description"
									inputChange={this.handleInputChange}
								/>
							</ProjectInputContainer>

							<ProjectInputContainer>
								<label>URL</label>
								<Input 
									placeholder="Project URL" 
									inputName="url"
									inputChange={this.handleInputChange}
								/>
							</ProjectInputContainer>

							<ProjectInputContainer>
								<label>Github</label>
								<Input 
									placeholder="Project Github" 
									inputName="github"
									inputChange={this.handleInputChange}
								/>
							</ProjectInputContainer>

							<ProjectInputContainer>
								<label>Technologies</label>
								<Input 
									placeholder="Technologies" 
									inputName="technologies"
									inputChange={this.handleInputChange}
								/>
							</ProjectInputContainer>

							<ProjectInputContainer>
								<label>Image Url</label>
								<InputWithBtn 
									placeholder={imgUrl} 
									btnText="Add by Url" 
									inputChange={this.handleInputUrlChange}
									submitBtn={this.handleEditImageUrlSubmit}
								/>
							</ProjectInputContainer>

							<h3>Or</h3>

							<Dropzone submitDrop={this.handleImageDropSubmit} btnText={"Add by Drop"}/>
						</div>
					}
				</Modal>
			</>
		)
	}
}

function mapStateToProps({ auth }) {
  return { auth }
}

const mapDispatchToProps = dispatch => ({
  displayGlobalAlert: bindActionCreators(displayGlobalAlert, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(AddProjects)