////////////////////////////////////////////////////////
//                    Possible Colors                 //
//                                                    //
// SIDEBAR: borderRight: #b70000                      //
//                                                    //
////////////////////////////////////////////////////////

export const darkTheme = {
  GLOBAL_COLORS: {
    a: '#56b3ff;',
    aHover: '#BC75FF;',
    active: 'var(--theme-color);',

    subtext: '#b1b1b1;',
    subtextHover: {
      color: '#fff'
    },

    Gray: '#d0d0d0;',
    GrayHover: '#b3b3b3;',

    Green: 'var(--theme-color);',
    GreenHover: '#BC75FF;',

    Yellow: 'gold;',
    YellowHover: '#d2b206;',

    Red: '#ff4040;',
    RedHover: '#ce0808;',

    Purple: '#BC75FF;',
    PurpleHover: '#8745c5;'
  },

  THEME_BUTTON: {
    background: "#324047",
    boxShadow: "0px 0px 15px 8px rgba(253, 253, 253, 0.2)"
  },

  PARTICLES: {
    style: {
      background: "radial-gradient(ellipse at bottom, #1b2735 0%, #090a0f 100%)"
    }
  },

  SIDEBAR: {
    borderRight: "1px solid #56b3ff",
    mediaBackgroundColor: "#050e1f",
    mediaSpan: "#56b3ff",
    mediaHover: "#BC75FF"
  },

  CONTACT: {
    styles: {
      textColor: "#75FFBC",
      input: {
        color: "#89B2FF",
        dotBorder: "2px dashed rgba(255, 255, 255, 0.5)"
      }
    }
  }
}

export const lightTheme = {
  GLOBAL_COLORS: {
    a: '#3670d0;',
    aHover: '#7503e0;',
    active: '#49bf76;',

    subtext: '#333;',
    subtextHover: {
      color: '#000'
    },

    Gray: '#4e4e4e;',
    GrayHover: '#a7a7a7;',

    Green: '#00a52e',
    GreenHover: '#7503e0;',

    Yellow: '#b59900;',
    YellowHover: '#e2bf00;',
    
    Red: '#ce0808;',
    RedHover: '#ff4040;',
    
    Purple: '#7503e0;',
    PurpleHover: '#BC75FF;'
  },

  THEME_BUTTON: {
    background: "#EDEDE5",
    boxShadow: "box-shadow: 0px 0px 15px 8px rgba(0, 0, 0, 0.2)"
  },

  PARTICLES: {
    style: {
      background: "radial-gradient(ellipse at top right, #fffffd 0%, #e4e4df 100%)"
    }
  },

  SIDEBAR: {
    borderRight: "1px solid #757575",
    mediaBackgroundColor: "#e2e2e2",
    mediaSpan: "#0760a9",
    mediaHover: '#7503e0'
  },

  CONTACT: {
    styles: {
      textColor: "#00a52e",
      input: {
        color: "#0760a9",
        dotBorder: "2px dashed rgba(0, 0, 0, 0.5)"
      }
    }
  }
}