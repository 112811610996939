import React, { Component } from 'react'
import axios from 'axios'
import styled from "styled-components"
import PropTypes from 'prop-types'

import utilities from '../../utilities'

import Checkbox from '../../helper/StandardComponents/Checkbox'

const TechWrapper = styled.div`
	margin-bottom: 20px;
`

const TechTags = styled.span`
  background-color: #d0e5ff;
  padding: 8px 15px 3px 15px;
  margin: 8px 10px 8px 0;
  border-radius: 20px;
	font-size: 15px;
	cursor: pointer;

	&:hover {
		background-color: #d0d0d0;
	}
`

const TechSelectAll = styled.label`
	background-color: #d0e5ff;
	padding: 6px 15px 3px 15px;
	margin: 8px 10px 8px 0;
	border-radius: 20px;
	font-size: 15px;
	cursor: pointer;
`

class TechFilter extends Component {
	state = {
		selectAll: true,
		techList: [],
		filterList: []
	}

	async componentDidMount() {
		const res = await axios.get('/api/get_tech_list')
		let sortedTechs = res.data.techs.sort()

		this.setState({ 
			techList: sortedTechs,
			filterList: sortedTechs
		})
	}

	handelSelectAllChange = e => {
		const { renderFilteredProjects } = this.props
		let isChecked = e.target.checked 
		
		if (isChecked) {
			this.setState({ 
				selectAll: isChecked,
				filterList: this.state.techList 
			}, () => {
				renderFilteredProjects(this.state.filterList)
			})
		} else {
			this.setState({ 
				selectAll: isChecked,
				filterList: [] 
			}, () => {
				renderFilteredProjects(this.state.filterList)
			})
		}
	}

	handleTechBtnClick = e => {
		const { renderFilteredProjects } = this.props
		const { filterList, techList } = this.state
		const clickedTech = e.target.dataset.tech

		if (filterList.includes(clickedTech)) {
			const index = filterList.indexOf(clickedTech)

			if (index !== -1) {
				let newFilter = [...filterList]
				newFilter.splice(index, 1)
				this.setState({ 
					filterList: newFilter,
					selectAll: false 
				}, () => {
					renderFilteredProjects(this.state.filterList)
				})
			}
		} else {
			let newFilter = [...filterList]
			newFilter.push(clickedTech)

			let isAll = utilities.arraysEqual(newFilter, techList)

			this.setState({ 
				selectAll: isAll,
				filterList: newFilter 
			}, () => {
				renderFilteredProjects(this.state.filterList)
			})
		}
	}

	renderMappedTechList = () => {
		const { techList, filterList } = this.state
		
		return techList.map((tech, i) => {
			return (
				<TechTags 
					key={i} 
					onClick={this.handleTechBtnClick} 
					data-tech={tech}

					style={ !filterList.includes(tech) ? { backgroundColor: '#bbbbbb'} : {}}
				>
					{tech}
				</TechTags>
			)
		})
	}

	render() {
		return (
			<TechWrapper className="box">
				<TechSelectAll>
          <Checkbox
            checked={this.state.selectAll}
            onChange={this.handelSelectAllChange}
          />
          <span style={{ marginLeft: 8 }}>Select All</span>
        </TechSelectAll>
				{this.renderMappedTechList()}
			</TechWrapper>
		)
	}
}

TechFilter.propTypes = {
	renderFilteredProjects: PropTypes.func.isRequired
}

export default TechFilter