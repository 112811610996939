import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import styled from 'styled-components';

const getColor = (props) => {
  if (props.isDragAccept) {
      return '#00e676';
  }
  if (props.isDragReject) {
      return '#ff1744';
  }
  if (props.isDragActive) {
      return '#2196f3';
  }
  return '#eeeeee';
}

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  margin: 25px 50px;
  border-width: 2px;
  border-radius: 2px;
  border-color: ${props => getColor(props)};
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  cursor: pointer;
  transition: border .24s ease-in-out;

  & > p {
      cursor: pointer;
  }
`

const ThumbnailContainer = styled.aside`
	display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 16px 50px;
`

const Thumbnails = styled.div`
	display: inline-flex;
	border-radius: 2px;
	border: 1px solid #eaeaea;
	margin-bottom: 8px;
	margin-right: 8px;
	width: 100px;
	height: 100px;
	padding: 4px;
	box-sizing: border-box;
`

const ThumbnailInner = styled.div`
	display: flex;
  min-width: 0px;
	overflow: hidden;
	
	& > img {
		display: block;
		width: auto;
		height: 100%;
	}
`

const DropBtn = styled.button`
	color: white;
	background-color: #0b9831;
	border-color: #0b9831;
  border-radius: 2px;
`

const Dropzone = ({ submitDrop, btnText }) => {
	const [files, setFiles] = useState([])
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject
  } = useDropzone({
		accept: 'image/*',
		onDrop: acceptedFiles => {
			setFiles(acceptedFiles.map(file => Object.assign(file, {
        preview: URL.createObjectURL(file)
			})))
		}
	})

	const thumbnails = files.map(file => (
    <Thumbnails key={file.name}>
      <ThumbnailInner>
        <img src={file.preview} alt="dropzone preview" />
      </ThumbnailInner>
    </Thumbnails>
  ));
  
  return (
		<>
			<Container {...getRootProps({isDragActive, isDragAccept, isDragReject})}>
				<input {...getInputProps()} />
				<p>Drag 'n' drop some files here, or click to select files</p>
			</Container>
			<ThumbnailContainer>
				{thumbnails}
			</ThumbnailContainer>

			<DropBtn onClick={e => submitDrop(e, files)}>{btnText}</DropBtn>
		</>
  )
}

export default Dropzone