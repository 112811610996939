import styled from "styled-components"

export const ProjectCardHeader = styled.div`
  text-align: center;
  padding: 5px 3px 3px 3px;
  font-family: 'Josefin Sans', serif;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 2px;
  background-color: #3670d0;
  color: white;

  border-top-right-radius: 5px;
  border-top-left-radius: 5px;

  & > a {
    color: #fff;
  }
`

export const ProjectCard = styled.div`
  width: 300px;
  height: 255px;
  overflow: hidden;
  cursor: pointer;
  margin-right: 25px;
  margin-top: 15px;
  box-shadow: 7px 8px 15px -3px rgba(255, 255, 255, 0.54), 1px 1px 6px -2px rgba(0,0,0,.05);

  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;

  &:hover {
    -webkit-transform: scale(1.2, 1.2);
    transform: scale(1.2, 1.2);
  }

  & > img {
    width: 100%;
  }

  @media screen and (max-width: 600px) {
    left: 10%;
  }
`