import React from 'react'
import styled from "styled-components"

const Sup = styled.sup`
  position: relative;
  left: -5px;
`

const SpanText = props => {
  return props.str.split(' ').map((word, i, sentence) => {
    if (word === "/n") {
      word = <br/>
    } else if (word === "/sup") {
      word = <Sup>{sentence[i+1]}</Sup>
      sentence[i+1] = ""
    } else if (word.startsWith("/a~")) {
      let href = word.split("~")[1]
      let text = ""

      let j = i + 1
      while (sentence[j] !== "~/a") {
        text += sentence[j] + " "
        sentence[j] = ""
        j++
      }
      sentence[j] = ""

      word = <a href={href} target="_blank" rel="nofollow noopener noreferrer">{text}</a>
    }

    return <span key={i} title={sentence[i]}>{word} </span>
  })
}

export default SpanText
