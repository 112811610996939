import styled from "styled-components"

const MainMenu = styled.div`
  position: fixed;
  min-height: 400px;
  top: 0;
  bottom: 0;
  left: 0;
  width: 60px;
  overflow: hidden;
  transition: width .05s linear;
  z-index: 1000;

  border-right: ${props => props.theme.borderRight};

  &:hover {
    width: 155px;
    overflow: visible;
  }

  @media screen and (max-width: 600px) {
    position: relative;
    width: 100%;
    min-height: 50px;
    overflow: visible;
    background-color: ${props => props.theme.mediaBackgroundColor};

    &:hover {
      width: 100%;
    }
  }
`

export default MainMenu