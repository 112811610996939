import React from 'react'
import PropTypes from 'prop-types';

const style = {
  verticalAlign: 'top',
  cursor: "pointer",
  marginRight: "10px",
  marginLeft: "10px"
}

const EditJackContent = ({ editFn, dataInputBind }) => {
  return (
    <i className="fa fa-edit fa-sm yellow" style={style} onClick={editFn} data-inputbind={dataInputBind}></i>
  )
}

EditJackContent.propTypes = {
  editFn: PropTypes.func.isRequired,
  dataInputBind: PropTypes.string.isRequired
}

export default EditJackContent