import React from 'react'

const Warping = () => {
  return (
    <div className="warping-container">
      <div className='cont'>
        <div className='ring'></div>
        <div className='ring'></div>
        <div className='ring'></div>
        <div className='ring'></div>
        <div className='ring'></div>
        <div className='ring'></div>
        <div className='ring'></div>
        <div className='ring'></div>
        <div className='ring'></div>
        <div className='ring'></div>
        <div className='ring'></div>
        <div className='ring'></div>
        <div className='ring'></div>
        <div className='ring'></div>
        <div className='ring'></div>
      </div>
      <div className='text'>
        Please come back later<br/>
        Webpage is being warped in
      </div>
  </div>

  )
}

export default Warping
