import React from 'react'

export const SocialMediaBtns = ({ btns }) => {
	return (
		<div className="social-icons">

			{(btns === undefined || btns.length === 0 || btns.includes("github")) &&
				<a className="social-icon social-icon--github" href="https://github.com/asolace" target="_blank" rel="noopener noreferrer">
					<i className="fa fa-github"></i>
					<div className="tooltip">Github</div>
				</a>
			}
			
			{(btns === undefined || btns.length === 0 || btns.includes("twitter")) &&
				<a className="social-icon social-icon--twitter" href="https://twitter.com/Asolaces" target="_blank" rel="noopener noreferrer">
					<i className="fa fa-twitter"></i>
					<div className="tooltip">Twitter</div>
				</a>
			}

			{(btns === undefined || btns.length === 0 || btns.includes("linkedin")) &&
				<a className="social-icon social-icon--linkedin" href="https://www.linkedin.com/in/asolace" target="_blank" rel="noopener noreferrer">
					<i className="fa fa-linkedin"></i>
					<div className="tooltip">LinkedIn</div>
				</a>
			}

			{(btns === undefined || btns.length === 0 || btns.includes("instagram")) &&
				<a className="social-icon social-icon--instagram" href="https://www.instagram.com/asolace" target="_blank" rel="noopener noreferrer">
					<i className="fa fa-instagram"></i>
					<div className="tooltip">Instagram</div>
				</a>
			}

			{(btns === undefined || btns.length === 0 || btns.includes("gmail")) &&
				<a className="social-icon social-icon--gmail" href="mailto:asolace.work@gmail.com">
					<i className="fa fa-envelope"></i>
					<div className="tooltip">asoloce.work@gmail.com</div>
				</a>
			}
		</div>
	)
}