import React from 'react'
import PropTypes from 'prop-types';

const inputStyle = {
  display: 'block',
  width: '50%',
  // minWidth: '200px',
  // height: '20px',
  fontSize: '1rem',
  padding: '.5rem',
  border: '1px solid gray',
  background: 'rgba(255,255,255,0.1)',
  color: 'gold',
  borderTopLeftRadius: '2px',
  borderBottomLeftRadius: '2px',
}

const buttonStyle = {
  backgroundColor: '#0b9831',
  color: '#efffef',
  fontSize: '1rem',
  cursor: 'pointer',
  border: '1px solid #0b9831',
  borderTopLeftRadius: 0,
  borderBottomLeftRadius: 0,
  borderTopRightRadius: '2px',
  borderBottomRightRadius: '2px',
}

const InputWithBtn = ({ placeholder, btnText, submitBtn, inputChange, value }) => {
  return (
    <div className="input-wrapper">
      <textarea type="text" style={inputStyle} placeholder={placeholder} onChange={inputChange} value={value} rows="5"/>
      <div className="input-button-right">
        <button type="button" style={buttonStyle} onClick={submitBtn}>{btnText}</button>
      </div>
    </div>
  )
}

InputWithBtn.propTypes = {
  placeholder: PropTypes.string,
  btnText: PropTypes.string,
  submitBtn: PropTypes.func.isRequired,
  inputChange: PropTypes.func.isRequired,
}

export default InputWithBtn