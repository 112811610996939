import { CHANGE_THEME } from '../actions/types'
import { darkTheme } from "../theme";

const initialState = { ...darkTheme }

export default function(state = initialState, action) {
  switch (action.type) {
    case CHANGE_THEME:
      let newState = {
         ...state.theme, ...action.payload
      }
      return newState
    default:
      return state
  }
}
