import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Helmet } from 'react-helmet'
import axios from 'axios'

import SpanLetters from '../templates/SpanLetters'
import { SocialMediaBtns } from '../features/SocialMediaBtns'

class Contact extends Component {
  state = {
    name: '',
    email: '',
    message: '',
    sent: false,

    styles: {
      input: {
        color: "#89B2FF",
        dotBorder: "2px dashed rgba(255, 255, 255, 0.5)"
      }
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { CONTACT } = nextProps.theme
    
		if (CONTACT.styles !== prevState.styles) {
			return ({ styles: CONTACT.styles })
		}

		return null
	}

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value })
  }

  onSubmit = event => {
    event.preventDefault()
    axios.post('/api/contact_me', this.state)
    this.setState({
      name: '',
      email: '',
      message: '',
      sent: true
    })
    setTimeout(() => {
      this.setState({sent: false})
    }, 3000)
  }

  render() {
    const { name, email, styles } = this.state
    let nameLength = name.length > 8 ? name.length / 1.3 : 8
    let emailLength = email.length > 8 ? email.length / 1.3 : 8
    return (
      <div className="container">
        <Helmet>
          <meta charSet="utf-8" />
          <title>Asolace - Contact</title>
          <meta name="description" content="Let us know what we can do for you!" />
          <meta name="keywords" content="software, engineer, web developer, personal website, react, projects, contact" />
        </Helmet>

        <div className='main-title'>
          <SpanLetters str="Contact Me" />

          <SocialMediaBtns />

          <div className="form-container">
            <form className="contact-form" onSubmit={this.onSubmit}>
              <fieldset>
                <legend>Contact Form</legend>
                <p>Dear Jack, </p>

                <p>
                  My
                  <label htmlFor="name"> name</label> is
                  <input
                    className="contact-input"
                    type="text"
                    name="name"
                    value={this.state.name}
                    onChange={this.onChange}
                    placeholder="( your name here )"
                    style={{
                      width: `${nameLength}rem`,
                      color: styles.input.color,
                      borderBottom: styles.input.dotBorder
                    }}
                    required
                  />
                  and
                </p>

                <p>
                  my
                  <label htmlFor="email"> email address</label> is
                  <input
                    className="contact-input"
                    type="email"
                    name="email"
                    value={this.state.email}
                    onChange={this.onChange}
                    placeholder="( your email here )"
                    style={{
                      width: `${emailLength}rem`,
                      color: styles.input.color,
                      borderBottom: styles.input.dotBorder
                    }}
                    required
                  />.
                </p>

                <p> I have a
                  <label htmlFor="message"> message</label> for you,
                </p>
                <p>
                  <textarea
                    className="contact-textarea"
                    name="message"
                    value={this.state.message}
                    onChange={this.onChange}
                    placeholder="( your msg here... )"
                    style={{
                      color: styles.input.color,
                      borderBottom: styles.input.dotBorder
                    }}
                    required
                  />
                </p>

                <div className="contact-btn-wrapper">
                  <button
                    className={ this.state.sent ? "contact-btn contact-btn-send" : "contact-btn "}
                    type="submit"
                    style={ !this.state.sent ?
                      {
                        backgroundColor: '#0073ea',
                        border: '1px solid #0073ea'
                      } : {
                        backgroundColor: '#0bb500',
                        border: '1px solid #0bb500'
                      }
                    }
                    >
                    <span className="contact-btn-text">Send</span>
                    <span className="contact-btn-send">Sent!</span>
                    <span className="contact-btn-icon">
                      <i className="fa fa-paper-plane"></i>
                    </span>
                  </button>
                </div>

              </fieldset>
            </form>
          </div>
        </div>
      </div>
    )
  }
}

function mapStateToProps({ theme }) {
  return { theme }
}

export default connect(mapStateToProps)(Contact)
