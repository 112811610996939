import React, { Component } from 'react'
import { connect } from 'react-redux'
import Particles from 'react-particles-js'
import ParticlesConfig from './config'

class ParticlesBackground extends Component {
	state = {
		style: {
			background: 'radial-gradient(ellipse at bottom, #1b2735 0%, #090a0f 100%)'
		}
	}
	
	static getDerivedStateFromProps(nextProps, prevState) {
		const { PARTICLES } = nextProps.theme

		if (PARTICLES.style !== prevState.style) {
			return ({ style: PARTICLES.style })
		}

		return null
	}

	render() {
		const { style } = this.state

		return (
			<Particles params={ ParticlesConfig } style={ style } />
		)
	}
}

const mapStateToProps = state => ({ 
	theme: state.theme
}) 

export default connect(mapStateToProps)(ParticlesBackground)