import React from 'react'
import { Route, Redirect } from 'react-router-dom'
 
const PrivateRoute = ({ component: Component, auth, ...rest }) => {
	return (
		<Route {...rest} render={props => {
			return (
				(auth && auth.tea) ? 
				<Component {...props} /> : 
				<Redirect to={{ 
					pathname: "/",
					state: { from: props.location } 
				}}/>
			)
		}}/>
	)
}

export default PrivateRoute