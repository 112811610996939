import React, { Component } from 'react'
import { BrowserRouter, Switch, Route } from 'react-router-dom'
import { connect } from 'react-redux'
import * as actions from '../actions'

import './App.css'
import './stylesheets/Media.css'
import GlobalStyle from './GlobalStyles'

import ParticlesBackground from '../components/features/ParticlesBackground'

import About from './JackComponents/About'
import Advent from './Advent'
import Contact from './JackComponents/Contact'
import Dashboard from './Users/Dashboard'
import Donation from './Donation'
import Home from './Home'
import Sidebar from './Sidebar'
import Projects from './JackComponents/Projects'

import SamsFriedIceCream from './ClientProjects/SamsFriedIceCream'

import GlobalAlert from './GlobalAlert'
import PrivateRoute from './PrivateRoute'

import Warping from './helper/Warping'
import NotFound from './NotFound'
// import ThemeButton from './features/ThemeButton'

const SurveyNew = () => <Warping />

class App extends Component {
  state = {
    renderParticles: true
  }

  componentDidMount() {
    this.props.fetchUser()
  }

  render() {
    const { auth } = this.props

    return (
      <BrowserRouter>
        <div className="App">
          <GlobalStyle />

          {this.state.renderParticles && <ParticlesBackground />}

          {/* <ThemeButton /> */}

          <div className="App-container">
            <Switch>
              <PrivateRoute path="/adm-dashboard" auth={ auth } component={ Advent } />

              <Route exact path="/" component={ Home } />
              <Route path="/dashboard" component={ Dashboard } />
              <Route path="/about" component={ About } />
              <Route path="/projects" component={ Projects } />
              <Route path="/contact" component={ Contact } />
              <Route path="/donation" component={ Donation } />
              <Route path="/surveys/new" component={ SurveyNew } />

              <Route path="/SamsFriedIceCream" component={ SamsFriedIceCream } />

              <Route path="*" component={ NotFound } />
            </Switch>
          </div>

          <Sidebar />

          {this.props.globalAlert && <GlobalAlert />}
          
        </div>
      </BrowserRouter>
    )
  }
}

const mapStateToProps = ({ globalAlert, auth }) => ({ 
	auth, globalAlert
}) 

export default connect(mapStateToProps, actions)(App);
