import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from "redux";
import { displayGlobalAlert } from '../actions'
import styled from 'styled-components';

const Alert = styled.div`
  text-align: center;
  width: 70%;
  margin: auto;

  position: relative;
  top: 10px;
  padding: .75rem 1.25rem;
  border: 1px solid transparent;
`

const DismissBtn = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  padding: .75rem 1.25rem;
  color: inherit;

  background-color: transparent;
  border: 0;
  appearance: none;

  float: right;
  font-size: 1.3rem;
  font-weight: 700;
  line-height: 1;
  text-shadow: 0 1px 0 #fff;
  opacity: .5;
  cursor: pointer;
`

class GlobalAlert extends Component {
  handleGlobalAlertClose = e => {
    this.props.displayGlobalAlert("ALERT_CLOSE", null)
  }

  render() {
    const { globalAlert } = this.props

    return (
      <Alert className={globalAlert && globalAlert.alertType}>
        {globalAlert && globalAlert.message}

        <DismissBtn type="button" onClick={this.handleGlobalAlertClose}>
          <span aria-hidden="true">x</span>
        </DismissBtn>
      </Alert>
    )
  }
}

const mapStateToProps = state => ({ 
	globalAlert: state.globalAlert
}) 

const mapDispatchToProps = dispatch => ({
  displayGlobalAlert: bindActionCreators(displayGlobalAlert, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(GlobalAlert)