import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from "redux"
import { displayGlobalAlert } from '../../actions'
import { Helmet } from 'react-helmet'
import axios from 'axios'

import SpanLetters from '../templates/SpanLetters'
import SpanWords from '../templates/SpanWords'
import EditJackContent from '../helper/EditJackContent/EditJackContent'
import InputWithBtn from '../helper/StandardComponents/InputWithBtn'

import { SocialMediaBtns } from '../features/SocialMediaBtns'

class About2 extends Component {
  state = {
    page: "about",
    mainTitle: "",
    subText: "",

    showEditInput: false,
    editInputBind: "",

    inputValue: ""
  }

  async componentDidMount() {
    const defaultSubText = `Hi! I’m Jack, a software developer specialize in web development. I enjoy transforming websites into a beautiful (UI/UX), accessible (WCAG, ADA), and expressive representation of it’s content. I’m also always thinking of softwares to develop that can improve our lives.
    /n /n 
    When I’m not coding, designing or brainstorming, you’ll find me planting flowers, discovering local parks or drinking tea while staring into space. I also enjoy teaching and pouring out my brain to people.    
    `

    const resMain = await axios.get('/api/get_jack_content', {
      params: { page: "about", section: "mainTitle" }
    })
    const resSub = await axios.get('/api/get_jack_content', {
      params: { page: "about", section: "subText"  }
    })

    const textMain = resMain.data.jackContent && resMain.data.jackContent.text
    const textSub = resSub.data.jackContent && resSub.data.jackContent.text

    if (textMain !== null) {
      this.setState({ mainTitle: textMain })
    } else {
      this.setState({ mainTitle: "About Me" })
    }

    if (textSub !== null) {
      this.setState({ subText: textSub })
    } else {
      this.setState({ subText: defaultSubText })
    }
  }

  handleEditBtn = e => {
    const binding = e.target.getAttribute("data-inputbind")
    const {editInputBind, showEditInput, mainTitle, subText} = this.state

    this.setState({ 
      showEditInput: true,
      editInputBind: binding,
      inputValue: binding === "mainTitle" ? mainTitle : subText,
    })
  }

  handleEditInputChange = e => {
    const { editInputBind } = this.state
    if (editInputBind === "mainTitle") {
      this.setState({ 
        inputValue: e.target.value,
        mainTitle: e.target.value 
      })
    } else {
      this.setState({ 
        inputValue: e.target.value,
        subText: e.target.value 
      })
    }
  }

  handleEditSubmit = async e => {
    try {
      const res = await axios.post('/api/update_jack_content', {
        page: this.state.page,
        section: this.state.editInputBind,
        text: this.state.inputValue
      })
  
      if (res.data.success) {
        this.setState({ 
          inputValue: "",
          showEditInput: false
        })

        this.props.displayGlobalAlert("ALERT_SUCCESS", "Successfully updated about title. ")
      }
    } catch (err) {
      this.props.displayGlobalAlert("ALERT_DANGER", "Failed to update about title.")
      console.log("Fail to update...", e)
    }
  }

  render() {
    const { auth } = this.props
    const { mainTitle, subText, editInputBind, inputValue } = this.state

    return (
      <div className="container">
        <Helmet>
          <meta charSet="utf-8" />
          <title>Asolace - About</title>
          <meta name="description" content="Jack He - Software developer and entrepreneur specialize web development while drinking tea and staring into space." />
          <meta name="keywords" content="software, engineer, web developer, personal website, react, projects" />
        </Helmet>

        <div className='main-title'>
        {this.state.showEditInput && auth && auth.tea && 
            <InputWithBtn 
              btnText="Update" 
              submitBtn={this.handleEditSubmit} 
              inputChange={this.handleEditInputChange}
              binding={editInputBind}
              value={inputValue}
            />
          }
          
          {auth && auth.tea && 
            <EditJackContent editFn={this.handleEditBtn} dataInputBind="mainTitle" />
          }
          <SpanLetters str={mainTitle} /> 

          <SocialMediaBtns />
          
          {auth && auth.tea && 
            <EditJackContent editFn={this.handleEditBtn} dataInputBind="subText"/>
          }
          <div className="subtext">
            <SpanWords str={subText}/>
          </div>
        </div>
      </div>
    )
  }

}

function mapStateToProps({ auth }) {
  return { auth }
}

const mapDispatchToProps = dispatch => ({
  displayGlobalAlert: bindActionCreators(displayGlobalAlert, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(About2)