import axios from 'axios'
import { FETCH_USER, CHANGE_THEME } from './types'

export const fetchUser = () => async dispatch => {
  const res = await axios.get('/api/current_user')
  dispatch({ type: FETCH_USER, payload: res.data })
}

export const handelToken = (token) => async dispatch => {
  const res = await axios.post('/api/stripe', token)
  dispatch({ type: FETCH_USER, payload: res.data })
}

export const changeTheme = (theme) => dispatch => {
  dispatch({ type: CHANGE_THEME, payload: theme })
}

export const displayGlobalAlert = (type, message) => dispatch => {
  dispatch({ type: type, payload: message})
}