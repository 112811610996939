import React from 'react'

const style = {
	marginLeft: '10px',
	color: '#565656',
	cursor: 'pointer'
}

const DeleteIcon = ({ handleEditTech, value }) => {
	return (
		<i className="fas fa-times" style={style} onClick={e => handleEditTech(e, "delete", value)}></i>
	)
}

export default DeleteIcon