import styled from "styled-components"

export const Section = styled.li`
  margin: 7px 0;
  display: block;
  width: 195px;
  padding-left: 5px;

  @media screen and (max-width: 600px) {
    position: absolute;
    top: 7px;
    
    width: 90px !important;
    height: 30px;
    background-color: ${props => props.theme.mediaBackgroundColor};
    overflow: hidden;
    outline: 0;
    padding: 0;

    &:hover {
      overflow: visible;
    }
  }
`

export const JackInfo = styled(Section)`
  position: absolute;
  top: 35%;

  @media screen and (max-width: 600px) {
    left: 25%;
  }
`

export const UserInfo = styled(Section)`
  @media screen and (max-width: 600px) {
    left: 55%;
  }
`

export const Login = styled(Section)`
  position: absolute;
  bottom: 0;

  @media screen and (max-width: 600px) {
    position: absolute;
    bottom: -7px;
    right: 20px;
    width: 50px !important;

    & > a {
      display: unset;
      position: relative;
      margin: 0px !important;
      width: 150px !important;
      padding: 5px;
      right: 10px;
      background-color: ${props => props.theme.mediaBackgroundColor};
    }

    & > a > span {
      display: none;
    }

  }
`

export const DisabledLogin = styled(Section)`
  cursor: not-allowed;
  color: gray;
  opacity: 0.5;
`

export const FA = styled.i`
  position: relative;
  display: table-cell;
  width: 50px;
  height: 36px;
  text-align: center;
  vertical-align: middle;
`

export const NavMedia = styled.span`
  color: ${props => props.theme.mediaSpan};
  text-indent: -100%;
  white-space: nowrap;
  overflow: hidden;
  display: none;

  @media screen and (max-width: 600px) {
    display: inline-block;
    cursor: pointer;
    text-indent: 0;

    &:hover {
      color: ${props => props.theme.mediaHover};
    }
  }
`