import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from "redux";
import { displayGlobalAlert } from '../../../../actions'
import PropTypes from 'prop-types'
import axios from 'axios'

import SpanWords from '../../../templates/SpanWords'
import { ProjectCard, ProjectCardHeader } from '../ProjectCard'
import Modal from '../../../helper/Modal'
import Dropzone from '../../../helper/Dropzone'
import EditJackContent from '../../../helper/EditJackContent/EditJackContent'
import InputWithBtn from '../../../helper/StandardComponents/InputWithBtn'
import DeleteIcon from './DeleteIcon'
import { ProjectImageEditOverlay,
	ProjectInputContainer,
  ProjectModalDetails, 
	ProjectModalTitle, 
	ProjectGithub,
	ProjectModalText, 
	ProjectModalViewProject,
	ProjectAdminModalEdits, 
	ProjectModalTags, 
  ProjectHideButton } from '../ProjectModal/ProjectModalStyle'

class ProjectModal extends Component {
	state = {
		cardId: null,

		activeModal: null,

		showEditInput: false,
    editInputBind: "",
		inputEditValue: "",
		
		editImageInput: false,
		editInputImageBind: "Image Url",
	}

	componentDidMount() {
		this.setState({ cardId: this.props.project._id })
	}

	openModal = (e, i) => {
    this.setState({ activeModal: i })
  }
  closeModal = () => {
    this.setState({ activeModal: null })
  }

	mappedTech = array => {
		const { auth } = this.props

    return array.map((e, i) => {
      return (
        <ProjectModalTags key={i}>
					{e} 
					{auth && auth.tea && 
						<DeleteIcon handleEditTech={this.handleEditTech} value={e}/>
					}
				</ProjectModalTags>
      )
    })
	}

	handleEditImage = e => {
		this.setState({ editImageInput: true })
	}

	handleEditImageUrlSubmit = async e => {
		const { inputEditValue, cardId } = this.state

		try {
			let res = await axios.post('/api/edit_image_url', {
				_id: cardId,
				url: inputEditValue
			})

			if (res.data.success) {
				this.setState({ 
					inputEditValue: "",
					editImageInput: false
				})

				this.props.displayGlobalAlert("ALERT_SUCCESS", `Successfully updated image url.`)
			}
		} catch (err) {
			this.props.displayGlobalAlert("ALERT_DANGER", `Failed to update image url.`)
			console.log("Fail to update...", err)
		}
	}

	handleImageDropSubmit = async (e, files) => {
		const { cardId } = this.state
		const File = files[0]

		if (File !== undefined) {
			try {
				let res = await axios.post('/api/upload_to_aws_s3', {
					filename: File.name,
					filetype: File.type
				})

				let options = {
					headers: {
						'Content-Type': File.type,
						'x-amz-acl': 'public-read'
					}
				}

				if (res.data.success) {
					let awsRes = await axios.put(res.data.uploadUrl, File, options)

					if (awsRes.status === 200) {
						let url = awsRes.config.url.split("?")[0]

						try {
							let resUpdateUrl = await axios.post('/api/edit_image_url', {
								_id: cardId,
								url: url
							})

							if (resUpdateUrl.data.success) {
								this.setState({ 
									inputEditValue: "",
									editImageInput: false
								})
				
								this.props.displayGlobalAlert("ALERT_SUCCESS", `Successfully updated image url.`)
							}
						} catch (err) {
							this.props.displayGlobalAlert("ALERT_DANGER", `Failed to update image url.`)
							console.log("Fail to update...", err)
						}
					} else {
						this.props.displayGlobalAlert("ALERT_DANGER", `Failed to upload to AWS bucket with status ${awsRes.status}.`)
					}
				}
			} catch (err) {
				this.props.displayGlobalAlert("ALERT_DANGER", `Failed to update image url.`)
				console.log("Fail to update...", err)
			}
		} else {
			this.props.displayGlobalAlert("ALERT_DANGER", `No Image in dropzone.`)
		}
	}

	handleEditBtn = e => {
    let binding = e.target.getAttribute("data-inputbind")

    this.setState({ 
      showEditInput: !this.state.showEditInput,
      editInputBind: binding
    })
	}

  handleEditInputChange = e => {
		this.setState({ 
			inputEditValue: e.target.value
		})
  }
	
	handleEditSubmit = async e => {
		const { editInputBind, inputEditValue, cardId } = this.state

		if (editInputBind === "tech") {
			this.handleEditTech(e, "add", inputEditValue)
		} else {
			try {
				let res = await axios.post('/api/edit_' + editInputBind, {
					_id: cardId,
					section: editInputBind,
					text: inputEditValue
				})
		
				if (res.data.success) {
					this.setState({ 
						inputEditValue: "",
						editInputBind: "",
						showEditInput: false
					})

					this.props.displayGlobalAlert("ALERT_SUCCESS", `Successfully updated ${editInputBind}.`)
				}
			} catch (err) {
				this.props.displayGlobalAlert("ALERT_DANGER", `Failed to update ${editInputBind}.`)
				console.log("Fail to update...", e)
			}
		}
	}
	
	handleEditTech = async (e, action, value) => {
		const { cardId } = this.state

		if (action === "delete") e.persist()
		
		try {
      let res = await axios.post('/api/update_tech', {
        _id: cardId,
				tech: value,
				action: action
      })
  
      if (res.data.success) {
				if (action === "delete") e.target.parentNode.remove()

				if (action === "add") {
					this.setState({ 
						inputEditValue: "",
						editInputBind: "",
						showEditInput: false
					})
				}

        this.props.displayGlobalAlert("ALERT_SUCCESS", `Successfully updated Technologies.`)
      }
    } catch (err) {
      this.props.displayGlobalAlert("ALERT_DANGER", `Failed to update to update Technologies.`)
      console.log("Fail to update...", e)
    }
	}
	  
  handleProjectHide = e => {
    const { cardId } = this.state
    const isHidden = (e.target.getAttribute("ishidden") === "true")

    try {
      axios.post('/api/hide_unhide_project', {
        id: cardId,
        isHidden: !isHidden
      })
      this.props.displayGlobalAlert("ALERT_SUCCESS", `Success, his project is now ${!isHidden ? "Hidden" : "Shown"}`)
    } catch (err) {
      this.props.displayGlobalAlert("ALERT_DANGER", "Failed to get all projects.")
      console.log(err)
    }
  }

	render() {
		const { editInputBind, editImageInput, showEditInput, activeModal, editInputImageBind } = this.state
		const { name, thumbnail, description, technologies, url, github, isHidden } = this.props.project
		const { i } = this.props.index
		const { auth } = this.props

		return (
			<React.Fragment key={i}>
				<ProjectCard onClick={e => this.openModal(e, i)}>
					<ProjectCardHeader>{name}</ProjectCardHeader>
					<img src={thumbnail} alt={`project ${name}`} />
				</ProjectCard>

				<Modal show={activeModal === i} onClose={this.closeModal}>
					<ProjectCardHeader>
						<a href={url} target="_blank" rel="noopener noreferrer">{name}</a>
					</ProjectCardHeader>

					<ProjectImageEditOverlay>
						<img src={thumbnail} alt={`project ${name}`}/>
						{auth && auth.tea && 
							<p onClick={this.handleEditImage}>Edit</p>
						}
					</ProjectImageEditOverlay>

					{auth && auth.tea && editImageInput && 
						<>
							<ProjectInputContainer>
								<label>Edit Image</label>
								<InputWithBtn
									placeholder={editInputImageBind}
									btnText="Update"
									submitBtn={this.handleEditImageUrlSubmit} 
									inputChange={this.handleEditInputChange}
									binding={editInputImageBind}
								/>
							</ProjectInputContainer>

							<h3>Or</h3>

							<Dropzone submitDrop={this.handleImageDropSubmit} btnText={"Update"}/>
							<hr />
						</>
					}

					<ProjectModalDetails>
						{showEditInput && auth && auth.tea && 
							<InputWithBtn 
								placeholder={editInputBind}
								btnText="Update"
								submitBtn={this.handleEditSubmit} 
								inputChange={this.handleEditInputChange}
								binding={editInputBind}
							/>
						}
						<ProjectModalTitle>
							{auth && auth.tea && 
								<EditJackContent editFn={this.handleEditBtn} dataInputBind="name"/>
							}
							{name}

							{github && github !== "" &&
								<ProjectGithub link={github} />
							}

							{auth && auth.tea && 
								<EditJackContent editFn={this.handleEditBtn} dataInputBind="github"/>
							}
						</ProjectModalTitle>

						<div className="box">
							{this.mappedTech(technologies)}
							{auth && auth.tea && 
								<ProjectModalTags onClick={this.handleEditBtn} data-inputbind="tech">
									<i className="fas fa-plus" data-inputbind="tech" style={{ cursor: 'pointer', color: '#565656' }}></i>
								</ProjectModalTags>
							}
						</div>

						<ProjectModalText>
							{auth && auth.tea && 
								<EditJackContent editFn={this.handleEditBtn} dataInputBind="description"/>
							}
							<SpanWords str={description} />
						</ProjectModalText>
						
						{auth && auth.tea && 
							<EditJackContent editFn={this.handleEditBtn} dataInputBind="url"/>
						}

						{url && url !== '' &&
							<ProjectModalViewProject href={url} target="_blank" rel="noopener noreferrer">
							View Project <i className="fas fa-external-link-alt" style={{ fontSize: '10px', verticalAlign: 'top' }}></i>
						</ProjectModalViewProject>
						}
						
						<br />

						{auth && auth.tea && 
							<ProjectAdminModalEdits>
								<ProjectHideButton onClick={this.handleProjectHide}>
									<i className={`fas fa-eye${isHidden ? "" : "-slash"} fa-md`} ishidden={isHidden.toString()}></i>
								</ProjectHideButton>
							</ProjectAdminModalEdits>
						}
					</ProjectModalDetails>
				</Modal>
			</React.Fragment>
		)
	}
}

function mapStateToProps({ auth }) {
  return { auth }
}

const mapDispatchToProps = dispatch => ({
  displayGlobalAlert: bindActionCreators(displayGlobalAlert, dispatch)
})

ProjectModal.propTypes = {
  project: PropTypes.object.isRequired,
  index: PropTypes.number,
}

export default connect(mapStateToProps, mapDispatchToProps)(ProjectModal)
