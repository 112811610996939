import { combineReducers } from 'redux'
import authReducer from './authReducer'
import themeReducer from './themeReducer'
import globalAlertReducer from './globalAlertReducer'

export default combineReducers({
  auth: authReducer,
  theme: themeReducer,
  globalAlert: globalAlertReducer
})
