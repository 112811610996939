import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from "redux"
import { displayGlobalAlert } from '../actions'
import { Helmet } from 'react-helmet'
import axios from 'axios'

import RedEnvelope from './features/RedEnvelope'
import SpanLetters from './templates/SpanLetters'
import EditJackContent from './helper/EditJackContent/EditJackContent'
import InputWithBtn from './helper/StandardComponents/InputWithBtn'

class Home extends Component {
  state = {
    page: "home",
    mainTitle: "",
    subTitle: "",

    showEditInput: false,
    editInputBind: "",

    inputTitleValue: ""
  }

  async componentDidMount() {
    let resMain = await axios.get('/api/get_jack_content', {
      params: { page: "home", section: "mainTitle" }
    })
    let resSub = await axios.get('/api/get_jack_content', {
      params: { page: "home", section: "subTitle"  }
    })

    const textMain = resMain.data.jackContent && resMain.data.jackContent.text
    const textSub = resSub.data.jackContent && resSub.data.jackContent.text

    if (textMain !== null) {
      this.setState({ mainTitle: textMain })
    } else {
      this.setState({ mainTitle: "Hi, I'm Jack /n --Software Developer" })
    }

    if (textSub !== null) {
      this.setState({ subTitle: textSub })
    } else {
      this.setState({ subTitle: "Welcome!" })
    }
  }

  handleEditBtn = e => {
    let binding = e.target.getAttribute("data-inputbind")

    this.setState({ 
      showEditInput: !this.state.showEditInput,
      editInputBind: binding
    })
  }

  handelEditInputChange = e => {
    const { editInputBind } = this.state
    if (editInputBind === "mainTitle") {
      this.setState({ 
        inputTitleValue: e.target.value,
        mainTitle: e.target.value 
      })
    } else {
      this.setState({ 
        inputTitleValue: e.target.value,
        subTitle: e.target.value 
      })
    }
  }

  handleEditSubmit = async e => {
    try {
      let res = await axios.post('/api/update_jack_content', {
        page: this.state.page,
        section: this.state.editInputBind,
        text: this.state.inputTitleValue
      })
  
      if (res.data.success) {
        this.setState({ 
          inputTitleValue: "",
          showEditInput: false
        })

        this.props.displayGlobalAlert("ALERT_SUCCESS", "Successfully updated home title. ")
      }
    } catch (err) {
      this.props.displayGlobalAlert("ALERT_DANGER", "Failed to update home title.")
      console.log("Fail to update...", e)
    }
  }

  render() {
    const { auth } = this.props
    const { mainTitle, subTitle, editInputBind } = this.state

    return (
      <div className="container">
        <Helmet>
          <meta charSet="utf-8" />
          <title>Asolace</title>
          <meta name="description" content="Jack He - Web developer and entrepreneur. Develop and design your websites while staring into space." />
          <meta name="keywords" content="software, engineer, web developer, personal website, react, projects" />
        </Helmet>

        <div className='main-title'>
          {this.state.showEditInput && auth && auth.tea && 
            <InputWithBtn 
              placeholder={editInputBind}
              btnText="Update"
              submitBtn={this.handleEditSubmit} 
              inputChange={this.handelEditInputChange}
              binding={editInputBind}
            />
          }
          
          {auth && auth.tea && 
            <EditJackContent editFn={this.handleEditBtn} dataInputBind="mainTitle" />
          }
          <SpanLetters str={mainTitle} /> 
          
          <br/>

          {auth && auth.tea && 
            <EditJackContent editFn={this.handleEditBtn} dataInputBind="subTitle"/>
          }

          <p className="home-subtext">
            {subTitle}
          </p>
          
          <RedEnvelope />
        </div>
      </div>
    )
  }
}

function mapStateToProps({ auth }) {
  return { auth }
}

const mapDispatchToProps = dispatch => ({
  displayGlobalAlert: bindActionCreators(displayGlobalAlert, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(Home)