export default function(state = null, action) {
  switch (action.type) {
    case "ALERT_DANGER":
      state = {
        alertType: 'alert-danger',
        message: action.payload
      }
      return state

    case "ALERT_SUCCESS":
      state = {
        alertType: 'alert-success',
        message: action.payload
      }
      return state

    case "ALERT_WARNING":
      state = {
        alertType: 'alert-warning',
        message: action.payload
      }
      return state
    
    case "ALERT_CLOSE":
      state = null
      return state
      
    default:
      return state
  }
}
